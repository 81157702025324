<template>
  <div class="clientes">
    <h1 class="titulo-card fs-4">Conexões / Histórico</h1>
    <div class="form-group card-principal">
      <div class="d-flex justify-content-between">
        <div class="input-group mb-3">
          <button v-on:click="$router.go(-1)" class="btn btn-outline-primary">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width class="me-1"/>Voltar
          </button>
        </div>
        <div class="input-group mb-3">
          <input
              v-model="search.q"
              v-on:keyup.enter="handlePesquisa(true)"
              type="text"
              class="form-control"
              placeholder="Pesquisar"
              aria-label="Pesquisar"
              aria-describedby="button-addon2">
          <button v-on:click="handlePesquisa(true)" class="btn btn-outline-secondary" type="button" id="button-addon2">
            <font-awesome-icon :icon="['fas', 'search']" fixed-width class="me-1"/>
          </button>
        </div>
      </div>
      <div class="d-flex">
        <table class="table table-sm-pc table-striped table-hover">
          <thead>
            <tr>
              <th>Login</th>
              <th>Cliente</th>
              <th class="text-center" width="80">Status</th>
              <th class="text-center" width="135">Início da conexão</th>
              <th class="text-center" width="135">Fim da conexão</th>
              <th class="text-center" width="100">Tempo conectado</th>
              <th class="text-center" width="100">IPv4</th>
              <th class="text-center" width="80">Tráfego</th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="session in sessionList" :key="session.id" @click="seeSession(session)" :class="rowCss(session)" class="cursor-pointer">
                <td scope="row">{{session.username}}</td>
                <td>{{customerName(session.customerId)}}</td>
                <td>{{session.status}}</td>
                <td align="center">{{godatetime().toMysql(session.createdAt)}}</td>
                <td align="center">{{sessionEnd(session) || '-'}}</td>
                <td align="center">{{connectionDuration(session)}}</td>
                <td align="center">{{session.framedIpAddressStr}}</td>
                <td align="center">{{formatBytes(totalDownload(session) + totalUpload(session))}}</td>
              </tr>
            </tbody>
        </table>
      </div>
      <Paginador v-model="paginador" @change-paginacao="onChangePaginacao"/>
    </div>

    <!-- modal-isp-connection-info -->
    <!-- Button trigger modal -->
    <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modal-isp-connection-info" id="btn-modal-isp-connection-info-abre">
      Launch
    </button>

    <!-- Modal -->
    <div class="modal fade" id="modal-isp-connection-info" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-fullscreen-margin" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              <font-awesome-icon :icon="['fas', 'info-circle']" fixed-width/>
              Informações da conexão
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div v-if="session" class="modal-body">
            <p class="mb-0"><strong>Usuário: </strong>{{session.username}}</p>
            <p class="mb-0"><strong>
              IPv4: </strong>{{session.framedIpAddressStr}}
              <a :href="linkToConnection(session)" target="_blank">
                <font-awesome-icon :icon="['fas', 'external-link-alt']" fixed-width/>
              </a>

            </p>
            <p class="mb-0"><strong>Tempo: </strong>
              {{datetime().connectionDuration(godatetime().toDateTime(session.createdAt))}} - desde
              {{godatetime().toMysql(session.createdAt)}}
            </p>
            <p class="mb-0">
              <strong>Consumo: </strong>{{formatBytes(totalDownload(session) + totalUpload(session))}}
              <strong>/ Download: </strong>{{formatBytes(totalDownload(session))}}
              <strong>/ Upload: </strong>{{formatBytes(totalUpload(session))}}
            </p>
            <p class="mb-0"><strong>BNG: </strong>{{session.bngId}}</p>
            <p class="mb-0"><strong>SessionID: </strong>{{session.acctSessionId}}</p>

          </div>
          <div class="modal-footer">
            <div class="d-flex justify-content-between w-100">
              <button type="button" @click="confirmDisconnectSession(session)" class="btn btn-danger">Desconectar</button>
              <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" id="btn-modal-isp-connection-info-fecha">Fechar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modal-isp-connection-info -->
  </div>
</template>

<script>
import Paginador from '@/components/Paginador.vue'
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'
import clone from '@/modules/clone'
import model from '@/model'
import godatetime from '@/modules/godatetime'
import datetime from '@/modules/datetime'

export default {
  name: 'IspConnectionsHistory',
  components: {
    Paginador,
  },
  data() {
    return {
      /** @type {model.paginador} */
      paginador: clone(model.paginador),
      search: {
        q: '',
      },
      bngs: [],
      sessionList: [],
      customers: [],
      session: undefined,
    }
  },
  methods: {
    godatetime() {
      return godatetime;
    },

    datetime() {
      return datetime;
    },

    handlePesquisa(resetPage) {
      const q = this.search.q;
      let params = this.paginador.requestParams;
      if (resetPage) {
        params = this.paginador.requestParamsFirst;
      }

      loading(true);
      api.get(`/isp/sessions?${params}&q=${q}&orderBy[id]=desc`).then(res => {
        loading(false);
        this.sessionList = res.data.items;
        this.customers = res.data.aggregators.customers;
        this.paginador.pagina = res.data.page;
        this.paginador.totalItens = res.data.totalItems;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    refreshBngs() {
      loading(true);
      api.get('/isp/bngs?active=1&paginate.cancel').then(res => {
        loading(false);

        this.bngs = res.data.items;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    onChangePaginacao() {
      this.handlePesquisa();
    },

    seeSession(session) {
      this.session = session;
      this.modalConnectionInfoOpen();
    },

    modalConnectionInfoOpen() {
      document.getElementById('btn-modal-isp-connection-info-abre').click();
    },

    modalConnectionInfoClose() {
      document.getElementById('btn-modal-isp-connection-info-fecha').click();
    },

    linkToConnection(session) {
      return `http://${session.framedIpAddressStr}/`
    },

    totalDownload(session) {
      return (session.inputGigawords * (2 ** 32)) + session.inputOctets;
    },

    totalUpload(session) {
      return (session.outputGigawords * (2 ** 32)) + session.outputOctets;
    },

    sessionEnd(session) {
      if (session.status != 'disconnected') {
        return '';
      }

      let ref = godatetime.toDateTime(session.createdAt)
      ref = new Date(ref.getTime() + (session.sessionTime * 1000));

      return datetime.toMysql(ref);
    },

    customerName(id) {
      const customer = this.customers.find(x => x.id === id);
      if (!customer) {
        return '';
      }

      return customer.name;
    },

    // TODO: REFACTOR
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 b';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['b', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },

    confirmDisconnectSession(session) {
      dialogo.confirmacao(`Confirma encerrar a sessão <b>${session.acctSessionId}</b> do usuário <b>${session.username}</b>?`).then(() => {
        loading(true);
        api.delete(`/isp/sessions/${session.id}`).then(() => {
          loading(false);
          session.status = 'disconnected';
          this.modalConnectionInfoClose();
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },

    rowCss(session) {
      switch (true) {
        case session.status === 'active':
          return ['text-info'];

        case session.status === 'disconnected':
          return ['text-muted'];
      }
    },

    connectionDuration(session) {
      if (session.status === 'active') {
        return datetime.connectionDuration(godatetime.toDateTime(session.createdAt));
      }

      let duration = new Date();
      duration.setSeconds(duration.getSeconds() - session.sessionTime);

      return datetime.connectionDuration(duration);
    },
  },
  created() {
    this.handlePesquisa();
    this.refreshBngs();
  }
}
</script>
